import './Footer.css';
import LoadFont from '../utils/LoadFont';
import { NavDstEnum } from '../Constants';
import { useNavigate } from 'react-router-dom';

const fontUrl = "https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap";
LoadFont(fontUrl);

const Footer = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className='footer'>
                {/* <div className='footer-nav-btn-container'>
                    <button className="footer-nav-btn" onClick={() => navigate(NavDstEnum.ABOUT_US)}>About us</button>
                    <button className="footer-nav-btn" onClick={() => navigate(NavDstEnum.SERVICES)}>Services</button>
                    <button className="footer-nav-btn" onClick={() => navigate(NavDstEnum.OUR_WORK)}>Our work</button>
                    <button className="footer-nav-btn" onClick={() => navigate(NavDstEnum.CONTACT_US)}>Contact us</button>
                </div> */}

                <div className='footer-nav-btn-container'>
                    <button className="footer-nav-btn" onClick={() => {
                        const aboutSection = document.querySelector('.home-about-container');
                        if (aboutSection) {
                            aboutSection.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>About us</button>
                    <button className="footer-nav-btn" onClick={() => {
                        const servicesSection = document.querySelector('.home-services-container');
                        if (servicesSection) {
                            servicesSection.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>Services</button>
                    <button className="footer-nav-btn" onClick={() => {
                        const desktopElement = document.querySelector('.home-past-work-wrapper-desktop');
                        const mobileElement = document.querySelector('.home-past-work-wrapper-mobile');

                        if (window.getComputedStyle(desktopElement).display !== 'none') {
                            desktopElement.scrollIntoView({ behavior: 'smooth' });
                        } else if (window.getComputedStyle(mobileElement).display !== 'none') {
                            mobileElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>Our work</button>
                    <button className="footer-nav-btn"
                        onClick={() => {
                            window.location.href = "https://api.whatsapp.com/send?phone=601130476757";
                        }}>Contact us</button>
                </div>

                <div className='footer-logo-container-desktop'>
                    <svg width="100%" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 200V100H50.6531C36.9311 100 25.1387 95.0929 15.3475 85.2857C5.52059 75.475 0.625 63.7143 0.625 50C0.625 36.2857 5.52059 24.5214 15.3475 14.7143C25.1387 4.90355 36.9311 0 50.6531 0H200.737V100H150.709C164.431 100 176.188 104.904 186.015 114.714C195.806 124.521 200.737 136.286 200.737 150C200.737 163.714 195.806 175.475 186.015 185.286C176.188 195.093 164.431 200 150.709 200H0.625Z" fill="#FF3D00" />
                    </svg>
                    <svg width="100%" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 200V100H50.6531C36.9311 100 25.1387 95.0929 15.3475 85.2857C5.52059 75.475 0.625 63.7143 0.625 50C0.625 36.2857 5.52059 24.5214 15.3475 14.7143C25.1387 4.90355 36.9311 0 50.6531 0H200.737V100H150.709C164.431 100 176.188 104.904 186.015 114.714C195.806 124.521 200.737 136.286 200.737 150C200.737 163.714 195.806 175.475 186.015 185.286C176.188 195.093 164.431 200 150.709 200H0.625Z" fill="#FF3D00" />
                    </svg>
                    <svg width="100%" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 200V100H50.6531C36.9311 100 25.1387 95.0929 15.3475 85.2857C5.52059 75.475 0.625 63.7143 0.625 50C0.625 36.2857 5.52059 24.5214 15.3475 14.7143C25.1387 4.90355 36.9311 0 50.6531 0H200.737V100H150.709C164.431 100 176.188 104.904 186.015 114.714C195.806 124.521 200.737 136.286 200.737 150C200.737 163.714 195.806 175.475 186.015 185.286C176.188 195.093 164.431 200 150.709 200H0.625Z" fill="#FF3D00" />
                    </svg>
                    <svg width="100%" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 200V100H50.6531C36.9311 100 25.1387 95.0929 15.3475 85.2857C5.52059 75.475 0.625 63.7143 0.625 50C0.625 36.2857 5.52059 24.5214 15.3475 14.7143C25.1387 4.90355 36.9311 0 50.6531 0H200.737V100H150.709C164.431 100 176.188 104.904 186.015 114.714C195.806 124.521 200.737 136.286 200.737 150C200.737 163.714 195.806 175.475 186.015 185.286C176.188 195.093 164.431 200 150.709 200H0.625Z" fill="#FF3D00" />
                    </svg>
                </div>

                <div className='footer-soc-med-container-desktop'>
                    <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                        <a className='footer-nav-svg-btn' href="https://www.instagram.com/we.are.subtle/">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z" fill="#FF3D00" />
                            </svg>
                        </a>
                        <a className='footer-nav-svg-btn' href="https://www.linkedin.com/company/wearesubtle">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z" fill="#FF3D00" />
                            </svg>
                        </a>
                    </div>
                    <div>
                        <span
                            style={{
                                color: '#FF3D00',
                                fontSize: 18,
                                fontFamily: 'Instrument Sans',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            Drop us an email at{' '}
                        </span>
                        <a
                            className="footer-email-link"
                            href='mailto:hi@wearesubtle.co'
                        >
                            hi@wearesubtle.co
                        </a>
                    </div>

                    <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                        <a className='footer-nav-svg-btn' href="https://api.whatsapp.com/send?phone=601130476757">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6 21.7C13.6 22.3 14.8 22.6 16 22.6C19.7 22.6 22.6 19.6 22.6 16.1C22.6 14.3 22 12.7 20.7 11.4C19.4 10.2 17.8 9.5 16 9.5C12.4 9.5 9.39999 12.5 9.39999 16.1C9.39999 17.3 9.7 18.5 10.4 19.6L10.6 19.9L9.89999 22.3L12.4 21.6L12.6 21.7ZM18.2 17C18.4 17 19.4 17.5 19.6 17.6C19.6311 17.6156 19.6623 17.6287 19.6931 17.6417C19.8599 17.7121 20.0156 17.7779 20.1 18.2C20.2 18.2 20.2 18.6 20 19.1C19.9 19.5 19.1 20 18.7 20C18.6322 20 18.5673 20.0057 18.498 20.0119C18.1582 20.0419 17.712 20.0814 16.3 19.5C14.5475 18.799 13.3325 17.0999 12.9913 16.6228C12.9431 16.5554 12.9124 16.5124 12.9 16.5C12.883 16.466 12.8485 16.4116 12.8031 16.3399C12.5819 15.9906 12.1 15.2297 12.1 14.4C12.1 13.4 12.6 12.9 12.8 12.7C13 12.5 13.2 12.5 13.3 12.5H13.7C13.8 12.5 14 12.5 14.1 12.8C14.3 13.2 14.7 14.2 14.7 14.3C14.7 14.3333 14.7111 14.3667 14.7222 14.4C14.7445 14.4667 14.7667 14.5333 14.7 14.6C14.65 14.65 14.625 14.7 14.6 14.75C14.575 14.8 14.55 14.85 14.5 14.9L14.2 15.2C14.1 15.3 14 15.4 14.1 15.6C14.2 15.8 14.6 16.5 15.2 17C15.8751 17.5907 16.4078 17.8254 16.6778 17.9443C16.7278 17.9663 16.7688 17.9844 16.8 18C17 18 17.1 18 17.2 17.9C17.25 17.8 17.375 17.65 17.5 17.5C17.625 17.35 17.75 17.2 17.8 17.1C17.9 16.9 18 16.9 18.2 17Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C18.1 8 20.1 8.8 21.6 10.3C23.1 11.8 24 13.8 24 15.9C24 20.3 20.4 23.9 16 23.9C14.7 23.9 13.4 23.5 12.2 22.9L8 24L9.10001 20C8.40001 18.8 8 17.4 8 16C8 11.6 11.6 8 16 8Z" fill="#FF3D00" />
                            </svg>
                        </a>
                        <a className='footer-nav-svg-btn' href="https://linktr.ee/wearesubtle">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM12.9959 13.4207H9.03275H9V15.6151H12.9795L10.1464 18.3501L11.7021 19.9058L15.5507 16.0409L19.3992 19.8895L20.955 18.3501L18.1218 15.6151H22.1013V13.4043H18.1382L20.9713 10.7349L19.4156 9.16274L16.7298 11.8976V8H14.4043V11.8976L11.7185 9.16274L10.1627 10.7349L12.9959 13.4207ZM16.7462 18.7758H14.4207V24.0164H16.7462V18.7758Z" fill="#FF3D00" />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className='footer-soc-med-container-mobile'>
                    <div
                        style={
                            {
                                width: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 40,
                                display: 'inline-flex'
                            }}>
                        <a className='footer-nav-svg-btn' href="https://www.instagram.com/we.are.subtle/">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z" fill="#FF3D00" />
                            </svg>
                        </a>
                        <a className='footer-nav-svg-btn' href="https://www.linkedin.com/company/wearesubtle/">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z" fill="#FF3D00" />
                            </svg>
                        </a>
                        <a className='footer-nav-svg-btn' href="https://api.whatsapp.com/send?phone=601130476757">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6 21.7C13.6 22.3 14.8 22.6 16 22.6C19.7 22.6 22.6 19.6 22.6 16.1C22.6 14.3 22 12.7 20.7 11.4C19.4 10.2 17.8 9.5 16 9.5C12.4 9.5 9.39999 12.5 9.39999 16.1C9.39999 17.3 9.7 18.5 10.4 19.6L10.6 19.9L9.89999 22.3L12.4 21.6L12.6 21.7ZM18.2 17C18.4 17 19.4 17.5 19.6 17.6C19.6311 17.6156 19.6623 17.6287 19.6931 17.6417C19.8599 17.7121 20.0156 17.7779 20.1 18.2C20.2 18.2 20.2 18.6 20 19.1C19.9 19.5 19.1 20 18.7 20C18.6322 20 18.5673 20.0057 18.498 20.0119C18.1582 20.0419 17.712 20.0814 16.3 19.5C14.5475 18.799 13.3325 17.0999 12.9913 16.6228C12.9431 16.5554 12.9124 16.5124 12.9 16.5C12.883 16.466 12.8485 16.4116 12.8031 16.3399C12.5819 15.9906 12.1 15.2297 12.1 14.4C12.1 13.4 12.6 12.9 12.8 12.7C13 12.5 13.2 12.5 13.3 12.5H13.7C13.8 12.5 14 12.5 14.1 12.8C14.3 13.2 14.7 14.2 14.7 14.3C14.7 14.3333 14.7111 14.3667 14.7222 14.4C14.7445 14.4667 14.7667 14.5333 14.7 14.6C14.65 14.65 14.625 14.7 14.6 14.75C14.575 14.8 14.55 14.85 14.5 14.9L14.2 15.2C14.1 15.3 14 15.4 14.1 15.6C14.2 15.8 14.6 16.5 15.2 17C15.8751 17.5907 16.4078 17.8254 16.6778 17.9443C16.7278 17.9663 16.7688 17.9844 16.8 18C17 18 17.1 18 17.2 17.9C17.25 17.8 17.375 17.65 17.5 17.5C17.625 17.35 17.75 17.2 17.8 17.1C17.9 16.9 18 16.9 18.2 17Z" fill="#FF3D00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C18.1 8 20.1 8.8 21.6 10.3C23.1 11.8 24 13.8 24 15.9C24 20.3 20.4 23.9 16 23.9C14.7 23.9 13.4 23.5 12.2 22.9L8 24L9.10001 20C8.40001 18.8 8 17.4 8 16C8 11.6 11.6 8 16 8Z" fill="#FF3D00" />
                            </svg>
                        </a>
                        <a className='footer-nav-svg-btn' href="https://linktr.ee/wearesubtle">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM12.9959 13.4207H9.03275H9V15.6151H12.9795L10.1464 18.3501L11.7021 19.9058L15.5507 16.0409L19.3992 19.8895L20.955 18.3501L18.1218 15.6151H22.1013V13.4043H18.1382L20.9713 10.7349L19.4156 9.16274L16.7298 11.8976V8H14.4043V11.8976L11.7185 9.16274L10.1627 10.7349L12.9959 13.4207ZM16.7462 18.7758H14.4207V24.0164H16.7462V18.7758Z" fill="#FF3D00" />
                            </svg>
                        </a>
                    </div>

                    <div>
                        <span
                            style={{
                                color: '#FF3D00',
                                fontSize: 18,
                                fontFamily: 'Instrument Sans',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            Drop us an email at{' '}
                        </span>
                        <a
                            style={{
                                color: '#FF3D00',
                                fontSize: 18,
                                fontFamily: 'Instrument Sans',
                                fontWeight: '400',
                                textDecoration: 'underline',
                                wordWrap: 'break-word',
                                cursor: 'pointer',
                            }}
                            href='mailto:hi@wearesubtle.co'
                        >
                            hi@wearesubtle.co
                        </a>
                    </div>
                </div>
                <div className='footer-logo-container-mobile'>
                    <svg width="100%" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 200V100H50.6531C36.9311 100 25.1387 95.0929 15.3475 85.2857C5.52059 75.475 0.625 63.7143 0.625 50C0.625 36.2857 5.52059 24.5214 15.3475 14.7143C25.1387 4.90355 36.9311 0 50.6531 0H200.737V100H150.709C164.431 100 176.188 104.904 186.015 114.714C195.806 124.521 200.737 136.286 200.737 150C200.737 163.714 195.806 175.475 186.015 185.286C176.188 195.093 164.431 200 150.709 200H0.625Z" fill="#FF3D00" />
                    </svg>
                </div>
            </section>
        </>
    );
};

export default Footer;

