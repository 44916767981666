import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import OurWork from './pages/OurWork';
import ContactUs from './pages/ContactUs';
import { NavDstEnum } from './Constants';

const isInDevMode = false;

const RouteWithRedirectHandler = ({element}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  if (isInDevMode) {
    if (currentPath !== NavDstEnum.COMING_SOON) {
      return <Navigate to={NavDstEnum.COMING_SOON} replace />;
    }
    return element;
  }

  if (currentPath === NavDstEnum.COMING_SOON) {
    return <Navigate to={NavDstEnum.HOME} replace />;
  }

  return element;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path={NavDstEnum.HOME}
          element={<RouteWithRedirectHandler element={<Home />}
          fallback={<Home />} />}
        />
        <Route
          path={NavDstEnum.ABOUT_US}
          element={<RouteWithRedirectHandler element={<AboutUs />} />}
        />
        <Route
          path={NavDstEnum.SERVICES}
          element={<RouteWithRedirectHandler element={<Services />} />}
        />
        <Route
          path={NavDstEnum.OUR_WORK}
          element={<RouteWithRedirectHandler element={<OurWork />} />}
        />
        <Route
          path={NavDstEnum.CONTACT_US}
          element={<RouteWithRedirectHandler element={<ContactUs />} />}
        />
        <Route
          path={NavDstEnum.COMING_SOON}
          element={<RouteWithRedirectHandler element={<ComingSoon />}
          fallback={<ComingSoon />} />}
        />
        <Route
          path="*"
          element={<Navigate to={isInDevMode ? NavDstEnum.COMING_SOON : NavDstEnum.HOME} replace />}
        />
      </Routes>
    </Router>
  );
}

export default App;
