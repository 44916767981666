import './AboutUs.css';

import Header from '../components/Header';
import Footer from '../components/Footer';
import React, { useState, useEffect, useRef } from 'react';

import LoadFont from '../utils/LoadFont';

import aboutUsImg from '../images/about-us-img.png';

const fontUrl = "https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap";
LoadFont(fontUrl);

const AboutUs = () => {
  // Header var start
  const [isMobileNavBarOpen, setIsMobileNavBarOpen] = useState(false);

  const toggleNavBar = () => {
    setTimeout(() => {
      setIsMobileNavBarOpen((prevState) => !prevState);
    }, 100);
  };

  const [toChangeScrolledColor, setToChangeScrolledColor] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const updateScrolledColor = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        const sectionTop = rect.top + window.scrollY; // Section's top position relative to document
        setToChangeScrolledColor(window.scrollY > sectionTop); // Update boolean based on scroll position
      }
    };

    // Update on scroll and resize
    window.addEventListener('scroll', updateScrolledColor);
    window.addEventListener('resize', updateScrolledColor);

    // Initial update
    updateScrolledColor();

    return () => {
      window.removeEventListener('scroll', updateScrolledColor);
      window.removeEventListener('resize', updateScrolledColor);
    };
  }, [sectionRef]);

  useEffect(() => {
    document.body.style.overflow = isMobileNavBarOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileNavBarOpen]);
  // Header var end

  return (
    <div className='about-us-outer-container'>

      <Header
        isMobileNavBarOpen={isMobileNavBarOpen}
        toggleMobileNavBar={toggleNavBar}
        toChangeScrolledColor={toChangeScrolledColor}
      />

      <section className='about-us-hero-container'>
        <div>
          <span>
            About us
          </span>
          <h1>
            <span className="text-default">Subtle Studio isn't just another design studio. It's a </span>
            <span className="text-default text-italic">partnership.</span>
          </h1>
        </div>
      </section>

      <section className='about-us-content-outer-container' ref={sectionRef}>
        <div className='about-us-content-wrapper'>
          <div className='about-us-content-header'>
            <div>
              <span>
                01 _ ABOUT
              </span>
              <h2>
                <span className="text-default">How Subtle Studio got into the </span>
                <span className="text-default text-italic">picture</span>
                <span className="text-default">?</span>
              </h2>
            </div>

            <a href="https://api.whatsapp.com/send?phone=601130476757">
              <span>
                Start a project
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.4688 5V13.125C15.4688 13.2493 15.4194 13.3685 15.3315 13.4565C15.2436 13.5444 15.1243 13.5938 15 13.5938C14.8757 13.5938 14.7565 13.5444 14.6686 13.4565C14.5807 13.3685 14.5313 13.2493 14.5313 13.125V6.13125L5.33128 15.3313C5.24242 15.4141 5.12489 15.4591 5.00345 15.457C4.88201 15.4548 4.76615 15.4056 4.68026 15.3198C4.59438 15.2339 4.54518 15.118 4.54304 14.9966C4.5409 14.8751 4.58598 14.7576 4.66878 14.6687L13.8688 5.46875H6.87503C6.75071 5.46875 6.63148 5.41936 6.54357 5.33146C6.45566 5.24355 6.40628 5.12432 6.40628 5C6.40628 4.87568 6.45566 4.75645 6.54357 4.66854C6.63148 4.58064 6.75071 4.53125 6.87503 4.53125H15C15.1243 4.53125 15.2436 4.58064 15.3315 4.66854C15.4194 4.75645 15.4688 4.87568 15.4688 5Z" fill="black" />
              </svg>
            </a>
          </div>
          <p>
            The story of Subtle Studio begins with a spark of passion. Back in 2018, armed with a keen eye for design and an entrepreneurial spirit, Irfan Yao embarked on a freelance journey, laying the foundation for what would become Subtle Studio.
            <br/><br/>
            Irfan's commitment to excellence and client satisfaction quickly transformed his freelance venture into a sought-after design resource.
            <br/><br/>
            As the workload grew and the demand for Subtle Studio's services increased, it became clear that a new chapter was on the horizon.  In 2023,  Subtle Studio reached a significant milestone, officially registering as a business. This marked a turning point, solidifying the studio's presence in the design world and opening doors to new opportunities.
            <br/><br/>
            Today, Subtle Studio stands as a testament to Irfan's vision and hard work.  It's a thriving homegrown design studio, a hub of creativity where ideas are nurtured and brought to life.
          </p>
        </div>

        <img src={aboutUsImg} alt='About Us Cover' style={{ width: '100%' }} />
      </section>

      <Footer />
    </div>

  );
};

export default AboutUs;
