import './ComingSoon.css';
import LoadFont
 from '../utils/LoadFont';
const fontUrl = "https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap";

LoadFont(fontUrl);

const ComingSoon = () => {
  return (
    <>
      <div className='coming-soon-outer-container'>
        <div style={{ width: '100%', color: 'white', fontSize: 24, fontFamily: 'Instrument Sans', fontWeight: '400', wordWrap: 'break-word' }}>We know, we know. Another design studio. <br />But trust us, we're not like the others.</div>

        <div className='coming-soon-content-frame'>
          <svg width="201px" height="200px" boxSizing="border-box" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 200V100H50.538C36.8133 100 25.0186 95.0929 15.2254 85.2857C5.39655 75.475 0.5 63.7143 0.5 50C0.5 36.2857 5.39655 24.5214 15.2254 14.7143C25.0186 4.90355 36.8133 0 50.538 0H200.652V100H150.614C164.339 100 176.097 104.904 185.926 114.714C195.72 124.521 200.652 136.286 200.652 150C200.652 163.714 195.72 175.475 185.926 185.286C176.097 195.093 164.339 200 150.614 200H0.5Z" fill="white" />
          </svg>

          <div>
            <svg width='100%' viewBox="0 0 1006 144" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M56.1086 142.945C39.9683 142.945 27.1266 138.977 17.5833 131.041C8.04003 123.105 2.43578 111.777 0.770508 97.0568H16.1422C17.5513 107.681 21.5863 115.681 28.2474 121.057C34.9085 126.433 44.1315 129.121 55.9165 129.121C65.9081 129.121 73.4338 127.233 78.4937 123.457C83.5535 119.681 86.0834 114.145 86.0834 106.849C86.0834 100.577 84.3541 95.5849 80.8955 91.8728C77.4368 88.1608 72.0567 85.0888 64.7552 82.6568L41.5055 74.5928C29.7205 70.4968 21.0099 65.5368 15.3736 59.7128C9.73731 53.8888 6.91917 46.4968 6.91917 37.5368C6.91917 30.1128 8.77658 23.6808 12.4914 18.2408C16.2062 12.8008 21.4582 8.54475 28.2474 5.47275C35.0366 2.40075 42.9145 0.864746 51.8814 0.864746C65.7159 0.864746 76.7963 4.48077 85.1227 11.7128C93.449 18.9448 98.2527 29.2168 99.5337 42.5288H84.162C82.4967 32.9288 79.0701 25.8888 73.8821 21.4088C68.6942 16.9288 61.2325 14.6888 51.4971 14.6888C42.2741 14.6888 35.1006 16.5448 29.9767 20.2568C24.8528 23.9688 22.2909 29.2168 22.2909 36.0008C22.2909 41.7608 24.0843 46.3688 27.671 49.8248C31.2577 53.2808 36.8299 56.2888 44.3877 58.8488L69.1746 67.4888C79.9347 71.2008 88.0049 76.2248 93.385 82.5608C98.7651 88.8968 101.455 96.9288 101.455 106.657C101.455 118.177 97.5161 127.105 89.6381 133.441C81.7601 139.777 70.5836 142.945 56.1086 142.945Z" fill="white" />
              <path d="M144.878 143.136C138.473 143.136 132.773 141.825 127.777 139.2C122.781 136.576 118.874 132.864 116.056 128.064C113.238 123.264 111.829 117.728 111.829 111.456V43.2964H127.201V107.424C127.201 114.848 129.026 120.416 132.677 124.128C136.328 127.84 141.548 129.696 148.337 129.696C154.486 129.696 159.93 128.288 164.669 125.472C169.409 122.656 173.156 118.72 175.91 113.664C178.664 108.608 180.041 102.816 180.041 96.2884L182.539 119.52C179.336 126.816 174.341 132.576 167.552 136.8C160.762 141.024 153.205 143.136 144.878 143.136ZM181.002 141.216V118.176H180.041V43.2964H195.413V141.216H181.002Z" fill="white" />
              <path d="M263.051 142.942C253.828 142.942 246.078 140.702 239.801 136.222C233.524 131.742 229.233 125.406 226.927 117.214L229.041 116.83V141.214H214.63V2.97412H230.002V66.1422L227.888 65.5661C230.322 58.0141 234.773 52.0941 241.242 47.8061C247.711 43.5181 255.429 41.3741 264.396 41.3741C273.106 41.3741 280.696 43.4861 287.165 47.7101C293.634 51.9341 298.662 57.8222 302.248 65.3742C305.835 72.9262 307.629 81.7582 307.629 91.8702C307.629 102.11 305.771 111.07 302.056 118.75C298.342 126.43 293.154 132.382 286.493 136.606C279.832 140.83 272.018 142.942 263.051 142.942ZM260.745 129.502C270.48 129.502 278.102 126.142 283.61 119.422C289.119 112.702 291.873 103.518 291.873 91.8702C291.873 80.3502 289.119 71.2942 283.61 64.7022C278.102 58.1102 270.48 54.8142 260.745 54.8142C251.01 54.8142 243.324 58.1741 237.687 64.8941C232.051 71.6142 229.233 80.7982 229.233 92.4462C229.233 103.838 232.051 112.862 237.687 119.518C243.324 126.174 251.01 129.502 260.745 129.502Z" fill="white" />
              <path d="M358.161 142.942C347.273 142.942 339.363 140.478 334.431 135.55C329.499 130.622 327.033 123.486 327.033 114.142V20.4456L342.405 14.6855V113.95C342.405 119.454 343.814 123.486 346.632 126.046C349.45 128.606 354.062 129.886 360.467 129.886C363.029 129.886 365.238 129.726 367.096 129.406C368.953 129.086 370.458 128.67 371.611 128.158V141.214C370.33 141.726 368.505 142.142 366.135 142.462C363.765 142.782 361.107 142.942 358.161 142.942ZM306.858 56.3496V43.2936H371.611V56.3496H306.858Z" fill="white" />
              <path d="M395.247 2.97412H379.875V141.214H395.247V2.97412Z" fill="white" />
              <path d="M454.62 143.135C445.269 143.135 437.006 140.927 429.833 136.511C422.66 132.095 417.023 126.047 412.924 118.367C408.825 110.687 406.775 101.855 406.775 91.871C406.775 81.887 408.793 73.087 412.828 65.471C416.863 57.855 422.531 51.935 429.833 47.711C437.135 43.487 445.525 41.375 455.004 41.375C464.099 41.375 472.009 43.359 478.734 47.327C485.459 51.295 490.647 56.863 494.298 64.031C497.949 71.199 499.774 79.647 499.774 89.375C499.774 90.655 499.742 91.871 499.678 93.023C499.614 94.175 499.454 95.455 499.198 96.863H418.304V83.999H489.783L484.787 91.103C484.787 79.327 482.193 70.271 477.005 63.935C471.817 57.599 464.483 54.431 455.004 54.431C445.269 54.431 437.423 57.791 431.466 64.511C425.51 71.231 422.531 80.287 422.531 91.679C422.531 103.327 425.51 112.639 431.466 119.615C437.423 126.591 445.397 130.079 455.388 130.079C461.665 130.079 467.173 128.703 471.913 125.951C476.653 123.199 480.111 119.263 482.289 114.143H496.892C493.69 123.103 488.342 130.175 480.848 135.359C473.354 140.543 464.611 143.135 454.62 143.135Z" fill="white" />
              <path d="M558.764 142.945C542.624 142.945 529.782 138.977 520.239 131.041C510.695 123.105 505.091 111.777 503.426 97.0568H518.797C520.207 107.681 524.242 115.681 530.903 121.057C537.564 126.433 546.787 129.121 558.572 129.121C568.563 129.121 576.089 127.233 581.149 123.457C586.209 119.681 588.739 114.145 588.739 106.849C588.739 100.577 587.009 95.5849 583.551 91.8728C580.092 88.1608 574.712 85.0888 567.41 82.6568L544.161 74.5928C532.376 70.4968 523.665 65.5368 518.029 59.7128C512.393 53.8888 509.575 46.4968 509.575 37.5368C509.575 30.1128 511.432 23.6808 515.147 18.2408C518.862 12.8008 524.114 8.54475 530.903 5.47275C537.692 2.40075 545.57 0.864746 554.537 0.864746C568.371 0.864746 579.452 4.48077 587.778 11.7128C596.104 18.9448 600.908 29.2168 602.189 42.5288H586.817C585.152 32.9288 581.725 25.8888 576.537 21.4088C571.349 16.9288 563.888 14.6888 554.152 14.6888C544.929 14.6888 537.756 16.5448 532.632 20.2568C527.508 23.9688 524.946 29.2168 524.946 36.0008C524.946 41.7608 526.74 46.3688 530.326 49.8248C533.913 53.2808 539.485 56.2888 547.043 58.8488L571.83 67.4888C582.59 71.2008 590.66 76.2248 596.04 82.5608C601.42 88.8968 604.11 96.9288 604.11 106.657C604.11 118.177 600.171 127.105 592.293 133.441C584.415 139.777 573.239 142.945 558.764 142.945Z" fill="white" />
              <path d="M655.22 142.942C644.332 142.942 636.422 140.478 631.49 135.55C626.558 130.622 624.092 123.486 624.092 114.142V20.4456L639.464 14.6855V113.95C639.464 119.454 640.873 123.486 643.691 126.046C646.509 128.606 651.121 129.886 657.526 129.886C660.088 129.886 662.297 129.726 664.155 129.406C666.012 129.086 667.517 128.67 668.67 128.158V141.214C667.389 141.726 665.564 142.142 663.194 142.462C660.824 142.782 658.166 142.942 655.22 142.942ZM603.917 56.3496V43.2936H668.67V56.3496H603.917Z" fill="white" />
              <path d="M707.87 143.136C701.465 143.136 695.765 141.825 690.769 139.2C685.774 136.576 681.867 132.864 679.049 128.064C676.23 123.264 674.821 117.728 674.821 111.456V43.2964H690.193V107.424C690.193 114.848 692.018 120.416 695.669 124.128C699.32 127.84 704.54 129.696 711.329 129.696C717.478 129.696 722.922 128.288 727.661 125.472C732.401 122.656 736.148 118.72 738.902 113.664C741.656 108.608 743.033 102.816 743.033 96.2884L745.531 119.52C742.329 126.816 737.333 132.576 730.544 136.8C723.754 141.024 716.197 143.136 707.87 143.136ZM743.994 141.216V118.176H743.033V43.2964H758.405V141.216H743.994Z" fill="white" />
              <path d="M848.52 141.214V118.366L850.442 118.942C848.008 126.366 843.557 132.254 837.088 136.606C830.619 140.958 822.901 143.134 813.934 143.134C805.095 143.134 797.378 141.022 790.781 136.798C784.183 132.574 779.06 126.686 775.409 119.134C771.758 111.582 769.933 102.75 769.933 92.6382C769.933 82.3982 771.79 73.4382 775.505 65.7582C779.22 58.0782 784.408 52.1261 791.069 47.9021C797.73 43.6781 805.544 41.5661 814.511 41.5661C823.734 41.5661 831.483 43.8061 837.76 48.2861C844.037 52.7661 848.328 59.1021 850.634 67.2942L847.56 67.6782V2.97412H862.931V141.214H848.52ZM816.816 129.694C826.68 129.694 834.398 126.334 839.97 119.614C845.542 112.894 848.328 103.71 848.328 92.0622C848.328 80.6702 845.51 71.6462 839.874 64.9902C834.238 58.3342 826.552 55.0061 816.816 55.0061C807.081 55.0061 799.459 58.3342 793.951 64.9902C788.443 71.6462 785.689 80.8622 785.689 92.6382C785.689 104.158 788.443 113.214 793.951 119.806C799.459 126.398 807.081 129.694 816.816 129.694Z" fill="white" />
              <path d="M882.143 141.214V43.2941H897.515V141.214H882.143ZM880.99 21.0221V2.97412H898.668V21.0221H880.99Z" fill="white" />
              <path d="M957.079 143.135C947.472 143.135 939.017 140.895 931.716 136.415C924.414 131.935 918.746 125.823 914.711 118.079C910.676 110.335 908.658 101.599 908.658 91.871C908.658 82.015 910.708 73.311 914.807 65.759C918.906 58.207 924.574 52.255 931.812 47.903C939.049 43.551 947.472 41.375 957.079 41.375C966.814 41.375 975.301 43.551 982.538 47.903C989.776 52.255 995.412 58.207 999.447 65.759C1003.48 73.311 1005.5 82.015 1005.5 91.871C1005.5 101.599 1003.48 110.335 999.447 118.079C995.412 125.823 989.776 131.935 982.538 136.415C975.301 140.895 966.814 143.135 957.079 143.135ZM957.079 130.079C963.74 130.079 969.505 128.447 974.372 125.183C979.24 121.919 983.019 117.407 985.709 111.647C988.399 105.887 989.744 99.231 989.744 91.679C989.744 80.415 986.766 71.391 980.809 64.607C974.853 57.823 966.943 54.431 957.079 54.431C947.344 54.431 939.466 57.823 933.445 64.607C927.425 71.391 924.414 80.415 924.414 91.679C924.414 99.231 925.759 105.887 928.449 111.647C931.139 117.407 934.95 121.919 939.882 125.183C944.814 128.447 950.546 130.079 957.079 130.079Z" fill="white" />
            </svg>
          </div>
        </div>

        <div className='coming-soon-footer'>

          <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
            <div style={{ color: 'white', fontSize: 24, fontFamily: 'Instrument Sans', fontWeight: '400', wordWrap: 'break-word' }}>Got a project in the meantime?</div>
          </div>

          <a className='coming-soon-contact-us-btn' href='https://linktr.ee/sforsubtle'>
            <div>Contact us</div>

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4688 5V13.125C15.4688 13.2493 15.4194 13.3685 15.3315 13.4565C15.2436 13.5444 15.1243 13.5938 15 13.5938C14.8757 13.5938 14.7565 13.5444 14.6686 13.4565C14.5807 13.3685 14.5313 13.2493 14.5313 13.125V6.13125L5.33128 15.3313C5.24242 15.4141 5.12489 15.4591 5.00345 15.457C4.88201 15.4548 4.76615 15.4056 4.68026 15.3198C4.59438 15.2339 4.54518 15.118 4.54304 14.9966C4.5409 14.8751 4.58598 14.7576 4.66878 14.6687L13.8688 5.46875H6.87503C6.75071 5.46875 6.63148 5.41936 6.54357 5.33146C6.45566 5.24355 6.40628 5.12432 6.40628 5C6.40628 4.87568 6.45566 4.75645 6.54357 4.66854C6.63148 4.58064 6.75071 4.53125 6.87503 4.53125H15C15.1243 4.53125 15.2436 4.58064 15.3315 4.66854C15.4194 4.75645 15.4688 4.87568 15.4688 5Z" fill="white" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
