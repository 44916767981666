import './FAQWrapper.css';
import React, { useState } from 'react';

const FAQWrapper = ({ question, answer }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleFAQ = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <div className={`faq-wrapper ${isOpen ? 'active' : ''}`} onClick={toggleFAQ}>
            <div className="faq-question-wrapper">
                <span>
                    {question}
                </span>

                <div className={`plusminus ${isOpen ? 'active' : ''}`}></div>
            </div>
            <p className={isOpen ? 'active' : ''}>{answer}</p>
        </div>
    );
}

export default FAQWrapper;