import './OurWork.css';

const OurWork = () => {
    return (
      <div >
OurWork
      </div>
    );
  };
  
  export default OurWork;
  