import './Header.css';
import { NavDstEnum } from '../Constants';
import LoadFont from '../utils/LoadFont';
import { useNavigate } from 'react-router-dom';

const fontUrl = "https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap";
LoadFont(fontUrl);

const Header = ({ isMobileNavBarOpen, toggleMobileNavBar, toChangeScrolledColor }) => {
    const navigate = useNavigate();

    const mobileNavBarStyles = {
        backgroundColor: isMobileNavBarOpen || toChangeScrolledColor ? 'white' : '#FF3D00',
        height: isMobileNavBarOpen ? '100dvh' : '56px',
        transition: 'background-color 100ms ease-in-out, height 200ms ease-in-out',
    };

    return (
        <>
            {/* <section className="header-container-desktop">
                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                    <button className="header-nav-btn" onClick={() => navigate(NavDstEnum.ABOUT_US)}>About us</button>
                    <button className="header-nav-btn" onClick={() => navigate(NavDstEnum.SERVICES)}>Services</button>
                </div>
                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                    <button className="header-nav-btn" onClick={() => navigate(NavDstEnum.OUR_WORK)}>Our work</button>
                    <button className="header-nav-btn" onClick={() => navigate(NavDstEnum.CONTACT_US)}>Contact us</button>
                </div>
            </section> */}

            <section className="header-container-desktop">
                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                    <button className="header-nav-btn" onClick={() => {
                        const aboutSection = document.querySelector('.home-about-container');
                        if (aboutSection) {
                            aboutSection.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>About us</button>
                    <button className="header-nav-btn" onClick={() => {
                        const servicesSection = document.querySelector('.home-services-container');
                        if (servicesSection) {
                            servicesSection.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>Services</button>
                </div>
                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
                    <button className="header-nav-btn" onClick={() => {
                        const desktopElement = document.querySelector('.home-past-work-wrapper-desktop');
                        const mobileElement = document.querySelector('.home-past-work-wrapper-mobile');

                        if (window.getComputedStyle(desktopElement).display !== 'none') {
                            desktopElement.scrollIntoView({ behavior: 'smooth' });
                        } else if (window.getComputedStyle(mobileElement).display !== 'none') {
                            mobileElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>Our work</button>
                    <button className="header-nav-btn"
                        onClick={() => {
                            window.location.href = "https://api.whatsapp.com/send?phone=601130476757";
                        }}>Contact us</button>
                </div>
            </section>

            <section
                className="header-container-mobile"
                style={mobileNavBarStyles}
            >
                <div className='header-container-mobile-row1'>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: 0,
                            padding: 0,
                            height: "24px"
                        }}
                        onClick={() => navigate(NavDstEnum.HOME)}>
                        <svg width="153" height="24" viewBox="0 0 153 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 24V12H6.00337C4.35673 12 2.94165 11.4111 1.7667 10.2343C0.587471 9.057 0 7.64571 0 6C0 4.35429 0.587471 2.94257 1.7667 1.76571C2.94165 0.588426 4.35673 0 6.00337 0H24.0135V12H18.0101C19.6568 12 21.0675 12.5884 22.2468 13.7657C23.4217 14.9426 24.0135 16.3543 24.0135 18C24.0135 19.6457 23.4217 21.057 22.2468 22.2343C21.0675 23.4111 19.6568 24 18.0101 24H0Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M38.3455 20.5134C36.409 20.5134 34.8683 20.0372 33.7234 19.0849C32.5784 18.1326 31.906 16.7732 31.7062 15.0068H33.5505C33.7195 16.2817 34.2036 17.2417 35.0028 17.8868C35.802 18.532 36.9085 18.8545 38.3225 18.8545C39.5212 18.8545 40.4241 18.628 41.0312 18.1748C41.6382 17.7217 41.9418 17.0574 41.9418 16.1819C41.9418 15.4292 41.7343 14.8302 41.3193 14.3847C40.9044 13.9393 40.2589 13.5707 39.3829 13.2788L36.5935 12.3111C35.1796 11.8196 34.1345 11.2244 33.4583 10.5255C32.782 9.82666 32.4439 8.93962 32.4439 7.86442C32.4439 6.97354 32.6668 6.2017 33.1125 5.5489C33.5582 4.8961 34.1883 4.38538 35.0028 4.01674C35.8174 3.6481 36.7625 3.46378 37.8383 3.46378C39.4981 3.46378 40.8275 3.8977 41.8265 4.76554C42.8255 5.63338 43.4018 6.86602 43.5555 8.46346H41.7112C41.5114 7.31146 41.1003 6.46666 40.4779 5.92906C39.8555 5.39146 38.9602 5.12266 37.7922 5.12266C36.6857 5.12266 35.825 5.34538 35.2103 5.79082C34.5955 6.23626 34.2882 6.86602 34.2882 7.6801C34.2882 8.3713 34.5033 8.92426 34.9337 9.33898C35.364 9.7537 36.0325 10.1147 36.9393 10.4219L39.9131 11.4587C41.2041 11.9041 42.1723 12.507 42.8178 13.2673C43.4633 14.0276 43.786 14.9915 43.786 16.1588C43.786 17.5412 43.3134 18.6126 42.3683 19.3729C41.4231 20.1332 40.0822 20.5134 38.3455 20.5134Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M48.9958 20.5364C48.2273 20.5364 47.5434 20.3789 46.944 20.0641C46.3447 19.7492 45.8759 19.3037 45.5378 18.7277C45.1997 18.1517 45.0306 17.4874 45.0306 16.7348V8.55557H46.8749V16.2509C46.8749 17.1418 47.0939 17.81 47.5319 18.2554C47.9699 18.7009 48.5962 18.9236 49.4107 18.9236C50.1484 18.9236 50.8016 18.7546 51.3702 18.4167C51.9389 18.0788 52.3884 17.6065 52.7188 16.9997C53.0492 16.393 53.2145 15.698 53.2145 14.9146L53.5141 17.7025C53.1299 18.578 52.5306 19.2692 51.716 19.7761C50.9015 20.2829 49.9947 20.5364 48.9958 20.5364ZM53.3297 20.306V17.5412H53.2145V8.55557H55.0587V20.306H53.3297Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M63.1736 20.5131C62.0671 20.5131 61.1373 20.2443 60.3842 19.7067C59.6311 19.1691 59.1163 18.4087 58.8396 17.4257L59.0932 17.3796V20.3057H57.3643V3.71689H59.2085V11.2971L58.9549 11.2279C59.2469 10.3217 59.781 9.61129 60.5571 9.09673C61.3332 8.58217 62.2592 8.32489 63.335 8.32489C64.3801 8.32489 65.2907 8.57833 66.0668 9.08521C66.8429 9.59209 67.4461 10.2987 67.8764 11.2049C68.3067 12.1111 68.5219 13.171 68.5219 14.3844C68.5219 15.6132 68.2991 16.6884 67.8534 17.61C67.4077 18.5316 66.7853 19.2459 65.9861 19.7527C65.1869 20.2596 64.2494 20.5131 63.1736 20.5131ZM62.897 18.9003C64.065 18.9003 64.9794 18.4971 65.6403 17.6907C66.3011 16.8843 66.6316 15.7822 66.6316 14.3844C66.6316 13.002 66.3011 11.9153 65.6403 11.1243C64.9794 10.3332 64.065 9.93769 62.897 9.93769C61.729 9.93769 60.8068 10.3409 60.1306 11.1473C59.4544 11.9537 59.1163 13.0558 59.1163 14.4535C59.1163 15.8206 59.4544 16.9035 60.1306 17.7022C60.8068 18.5009 61.729 18.9003 62.897 18.9003Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M74.5846 20.513C73.2783 20.513 72.3293 20.2173 71.7376 19.6259C71.1459 19.0346 70.85 18.1783 70.85 17.057V5.81346L72.6943 5.12225V17.0339C72.6943 17.6944 72.8633 18.1783 73.2014 18.4855C73.5396 18.7927 74.0928 18.9463 74.8613 18.9463C75.1686 18.9463 75.4337 18.9271 75.6566 18.8887C75.8794 18.8503 76.06 18.8003 76.1983 18.7389V20.3056C76.0446 20.3671 75.8256 20.417 75.5413 20.4554C75.257 20.4938 74.9381 20.513 74.5846 20.513ZM68.4295 10.1219V8.55522H76.1983V10.1219H68.4295Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M79.034 3.71689H77.1898V20.3057H79.034V3.71689Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M86.1574 20.5362C85.0355 20.5362 84.0442 20.2713 83.1835 19.7413C82.3229 19.2114 81.6467 18.4857 81.1549 17.5641C80.6631 16.6425 80.4172 15.5826 80.4172 14.3845C80.4172 13.1865 80.6593 12.1305 81.1434 11.2165C81.6275 10.3026 82.3075 9.59221 83.1835 9.08533C84.0596 8.57845 85.0662 8.32501 86.2035 8.32501C87.2947 8.32501 88.2437 8.56309 89.0505 9.03925C89.8574 9.51541 90.4798 10.1836 90.9178 11.0437C91.3558 11.9039 91.5748 12.9177 91.5748 14.085C91.5748 14.2386 91.571 14.3845 91.5633 14.5228C91.5556 14.661 91.5364 14.8146 91.5057 14.9836H81.8004V13.4399H90.3761L89.7767 14.2924C89.7767 12.8793 89.4655 11.7925 88.8431 11.0322C88.2206 10.2719 87.3408 9.89173 86.2035 9.89173C85.0355 9.89173 84.0941 10.2949 83.3795 11.1013C82.6649 11.9077 82.3075 12.9945 82.3075 14.3615C82.3075 15.7593 82.6649 16.8767 83.3795 17.7138C84.0941 18.5509 85.0508 18.9695 86.2496 18.9695C87.0027 18.9695 87.6635 18.8044 88.2322 18.4741C88.8008 18.1439 89.2158 17.6716 89.477 17.0572H91.2291C90.8448 18.1324 90.2032 18.981 89.3041 19.6031C88.4051 20.2252 87.3561 20.5362 86.1574 20.5362Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M98.6523 20.5134C96.7158 20.5134 95.1751 20.0372 94.0301 19.0849C92.8852 18.1326 92.2128 16.7732 92.013 15.0068H93.8572C94.0263 16.2817 94.5104 17.2417 95.3096 17.8868C96.1087 18.532 97.2153 18.8545 98.6292 18.8545C99.828 18.8545 100.731 18.628 101.338 18.1748C101.945 17.7217 102.249 17.0574 102.249 16.1819C102.249 15.4292 102.041 14.8302 101.626 14.3847C101.211 13.9393 100.566 13.5707 99.6896 13.2788L96.9002 12.3111C95.4863 11.8196 94.4412 11.2244 93.765 10.5255C93.0888 9.82666 92.7507 8.93962 92.7507 7.86442C92.7507 6.97354 92.9735 6.2017 93.4192 5.5489C93.8649 4.8961 94.495 4.38538 95.3096 4.01674C96.1241 3.6481 97.0693 3.46378 98.1451 3.46378C99.8049 3.46378 101.134 3.8977 102.133 4.76554C103.132 5.63338 103.709 6.86602 103.862 8.46346H102.018C101.818 7.31146 101.407 6.46666 100.785 5.92906C100.162 5.39146 99.267 5.12266 98.099 5.12266C96.9924 5.12266 96.1318 5.34538 95.517 5.79082C94.9023 6.23626 94.5949 6.86602 94.5949 7.6801C94.5949 8.3713 94.8101 8.92426 95.2404 9.33898C95.6707 9.7537 96.3393 10.1147 97.246 10.4219L100.22 11.4587C101.511 11.9041 102.479 12.507 103.125 13.2673C103.77 14.0276 104.093 14.9915 104.093 16.1588C104.093 17.5412 103.62 18.6126 102.675 19.3729C101.73 20.1332 100.389 20.5134 98.6523 20.5134Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M110.225 20.513C108.918 20.513 107.969 20.2173 107.378 19.6259C106.786 19.0346 106.49 18.1783 106.49 17.057V5.81346L108.334 5.12225V17.0339C108.334 17.6944 108.503 18.1783 108.842 18.4855C109.18 18.7927 109.733 18.9463 110.501 18.9463C110.809 18.9463 111.074 18.9271 111.297 18.8887C111.52 18.8503 111.7 18.8003 111.838 18.7389V20.3056C111.685 20.3671 111.466 20.417 111.181 20.4554C110.897 20.4938 110.578 20.513 110.225 20.513ZM104.07 10.1219V8.55522H111.838V10.1219H104.07Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M116.541 20.5364C115.773 20.5364 115.089 20.3789 114.49 20.0641C113.89 19.7492 113.422 19.3037 113.083 18.7277C112.745 18.1517 112.576 17.4874 112.576 16.7348V8.55557H114.421V16.2509C114.421 17.1418 114.64 17.81 115.078 18.2554C115.516 18.7009 116.142 18.9236 116.956 18.9236C117.694 18.9236 118.347 18.7546 118.916 18.4167C119.485 18.0788 119.934 17.6065 120.264 16.9997C120.595 16.393 120.76 15.698 120.76 14.9146L121.06 17.7025C120.676 18.578 120.076 19.2692 119.262 19.7761C118.447 20.2829 117.54 20.5364 116.541 20.5364ZM120.875 20.306V17.5412H120.76V8.55557H122.604V20.306H120.875Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M133.416 20.3057V17.5639L133.647 17.6331C133.355 18.5239 132.821 19.2305 132.044 19.7527C131.268 20.275 130.342 20.5361 129.267 20.5361C128.206 20.5361 127.28 20.2827 126.489 19.7758C125.697 19.2689 125.082 18.5623 124.644 17.6561C124.206 16.7499 123.987 15.69 123.987 14.4766C123.987 13.2478 124.21 12.1726 124.656 11.251C125.102 10.3294 125.724 9.61513 126.523 9.10825C127.322 8.60137 128.26 8.34793 129.336 8.34793C130.442 8.34793 131.372 8.61673 132.125 9.15433C132.878 9.69193 133.393 10.4523 133.67 11.4353L133.301 11.4814V3.71689H135.145V20.3057H133.416ZM129.612 18.9233C130.796 18.9233 131.722 18.5201 132.39 17.7137C133.059 16.9073 133.393 15.8052 133.393 14.4075C133.393 13.0404 133.055 11.9575 132.379 11.1588C131.703 10.3601 130.78 9.96073 129.612 9.96073C128.444 9.96073 127.53 10.3601 126.869 11.1588C126.208 11.9575 125.878 13.0635 125.878 14.4766C125.878 15.859 126.208 16.9457 126.869 17.7367C127.53 18.5278 128.444 18.9233 129.612 18.9233Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M137.45 20.3057V8.55529H139.294V20.3057H137.45ZM137.312 5.88265V3.71689H139.433V5.88265H137.312Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                            <path d="M146.441 20.5362C145.288 20.5362 144.274 20.2674 143.398 19.7298C142.522 19.1922 141.842 18.4588 141.357 17.5295C140.873 16.6002 140.631 15.5519 140.631 14.3845C140.631 13.2018 140.877 12.1573 141.369 11.2511C141.861 10.3449 142.541 9.63061 143.409 9.10837C144.277 8.58613 145.288 8.32501 146.441 8.32501C147.609 8.32501 148.627 8.58613 149.495 9.10837C150.363 9.63061 151.04 10.3449 151.524 11.2511C152.008 12.1573 152.25 13.2018 152.25 14.3845C152.25 15.5519 152.008 16.6002 151.524 17.5295C151.04 18.4588 150.363 19.1922 149.495 19.7298C148.627 20.2674 147.609 20.5362 146.441 20.5362ZM146.441 18.9695C147.24 18.9695 147.931 18.7737 148.515 18.382C149.099 17.9903 149.553 17.4489 149.876 16.7577C150.198 16.0665 150.36 15.2677 150.36 14.3615C150.36 13.0098 150.002 11.9269 149.288 11.1129C148.573 10.2988 147.624 9.89173 146.441 9.89173C145.273 9.89173 144.327 10.2988 143.605 11.1129C142.883 11.9269 142.522 13.0098 142.522 14.3615C142.522 15.2677 142.683 16.0665 143.006 16.7577C143.328 17.4489 143.786 17.9903 144.377 18.382C144.969 18.7737 145.657 18.9695 146.441 18.9695Z" fill={toChangeScrolledColor ? "#FF3D00" : "white"} />
                        </svg>
                    </button>

                    <button
                        onClick={toggleMobileNavBar}
                        style={{
                            backgroundColor: 'transparent',
                            boxSizing: 'border-box',
                            border: 0,
                            padding: '4px',
                            width: '24px',
                            height: "24px"
                        }}>
                        {
                            (!isMobileNavBarOpen) ?
                                (<svg width="100%" height="100%" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.8125 7C17.8125 7.14918 17.7532 7.29226 17.6477 7.39775C17.5423 7.50324 17.3992 7.5625 17.25 7.5625H0.75C0.600816 7.5625 0.457742 7.50324 0.352252 7.39775C0.246763 7.29226 0.1875 7.14918 0.1875 7C0.1875 6.85082 0.246763 6.70774 0.352252 6.60225C0.457742 6.49676 0.600816 6.4375 0.75 6.4375H17.25C17.3992 6.4375 17.5423 6.49676 17.6477 6.60225C17.7532 6.70774 17.8125 6.85082 17.8125 7ZM0.75 1.5625H17.25C17.3992 1.5625 17.5423 1.50324 17.6477 1.39775C17.7532 1.29226 17.8125 1.14918 17.8125 1C17.8125 0.850816 17.7532 0.707742 17.6477 0.602252C17.5423 0.496763 17.3992 0.4375 17.25 0.4375H0.75C0.600816 0.4375 0.457742 0.496763 0.352252 0.602252C0.246763 0.707742 0.1875 0.850816 0.1875 1C0.1875 1.14918 0.246763 1.29226 0.352252 1.39775C0.457742 1.50324 0.600816 1.5625 0.75 1.5625ZM17.25 12.4375H0.75C0.600816 12.4375 0.457742 12.4968 0.352252 12.6023C0.246763 12.7077 0.1875 12.8508 0.1875 13C0.1875 13.1492 0.246763 13.2923 0.352252 13.3977C0.457742 13.5032 0.600816 13.5625 0.75 13.5625H17.25C17.3992 13.5625 17.5423 13.5032 17.6477 13.3977C17.7532 13.2923 17.8125 13.1492 17.8125 13C17.8125 12.8508 17.7532 12.7077 17.6477 12.6023C17.5423 12.4968 17.3992 12.4375 17.25 12.4375Z" fill={toChangeScrolledColor ? "#000000" : "white"} />
                                </svg>) :
                                (<svg height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1475 14.3525C15.2028 14.404 15.2471 14.4661 15.2778 14.5351C15.3086 14.6041 15.3251 14.6786 15.3265 14.7541C15.3278 14.8296 15.3139 14.9047 15.2856 14.9747C15.2573 15.0447 15.2152 15.1084 15.1618 15.1618C15.1084 15.2152 15.0447 15.2573 14.9747 15.2856C14.9047 15.3139 14.8296 15.3278 14.7541 15.3265C14.6786 15.3251 14.6041 15.3086 14.5351 15.2778C14.4661 15.2471 14.404 15.2028 14.3525 15.1475L8.00001 8.79595L1.64751 15.1475C1.54088 15.2469 1.39984 15.301 1.25411 15.2984C1.10839 15.2958 0.96935 15.2368 0.86629 15.1337C0.763231 15.0307 0.704197 14.8916 0.701626 14.7459C0.699055 14.6002 0.753147 14.4591 0.852507 14.3525L7.20407 8.00001L0.852507 1.64751C0.753147 1.54088 0.699055 1.39984 0.701626 1.25411C0.704197 1.10839 0.763231 0.96935 0.86629 0.86629C0.96935 0.763231 1.10839 0.704197 1.25411 0.701626C1.39984 0.699055 1.54088 0.753147 1.64751 0.852507L8.00001 7.20407L14.3525 0.852507C14.4591 0.753147 14.6002 0.699055 14.7459 0.701626C14.8916 0.704197 15.0307 0.763231 15.1337 0.86629C15.2368 0.96935 15.2958 1.10839 15.2984 1.25411C15.301 1.39984 15.2469 1.54088 15.1475 1.64751L8.79595 8.00001L15.1475 14.3525Z" fill="black" />
                                </svg>)
                        }
                    </button>
                </div>

                {/* <div className='header-container-mobile-row2'>
                    <button className="header-nav-btn-mobile" onClick={() => navigate(NavDstEnum.ABOUT_US)}>About us</button>
                    <button className="header-nav-btn-mobile" onClick={() => navigate(NavDstEnum.SERVICES)}>Services</button>
                    <button className="header-nav-btn-mobile" onClick={() => navigate(NavDstEnum.OUR_WORK)}>Our work</button>
                    <button className="header-nav-btn-mobile" onClick={() => navigate(NavDstEnum.CONTACT_US)}>Contact us</button>
                </div> */}

                <div className='header-container-mobile-row2'>
                    <button
                        className="header-nav-btn-mobile"
                        onClick={() => {
                            toggleMobileNavBar(); // Call the toggle function

                            setTimeout(() => {
                                const aboutSection = document.getElementById('home-about-container');
                                if (aboutSection) {
                                    aboutSection.scrollIntoView({ behavior: 'smooth' });
                                }
                            }, 300)

                        }}
                    >
                        About us
                    </button>

                    <button
                        className="header-nav-btn-mobile"
                        onClick={() => {
                            toggleMobileNavBar(); // Call the toggle function
                            setTimeout(() => {
                                const mobileElement = document.getElementById('home-services-container');
                                if (mobileElement && window.getComputedStyle(mobileElement).display !== 'none') {
                                    mobileElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start', // Ensures it scrolls to the start of the container
                                        inline: 'nearest',
                                    });
                                }
                            }, 300);
                        }}
                    >
                        Services
                    </button>

                    <button
                        className="header-nav-btn-mobile"
                        onClick={() => {
                            toggleMobileNavBar();

                            setTimeout(() => {
                                const mobileElement = document.getElementById('home-past-work-wrapper-mobile');
                                if (mobileElement && window.getComputedStyle(mobileElement).display !== 'none') {
                                    mobileElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start', // Ensures it scrolls to the start of the container
                                        inline: 'nearest',
                                    });
                                }
                            }, 300);
                        }}
                    >
                        Our work
                    </button>

                    <button className="header-nav-btn-mobile" onClick={() => {
                        window.location.href = "https://api.whatsapp.com/send?phone=601130476757";
                    }}>Contact us</button>
                </div>

                <svg width="100%" height="1" viewBox="0 0 343 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="343" y2="0.5" stroke="#DBDBDB" />
                </svg>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%', maxWidth: '315px',
                    margin: '0 auto',
                    marginTop: '64px',
                    justifyContent: 'space-between'
                }}>
                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.25 48V24H12.2567C8.96347 24 6.1333 22.8223 3.78341 20.4686C1.42494 18.114 0.25 15.2914 0.25 12C0.25 8.70857 1.42494 5.88514 3.78341 3.53142C6.1333 1.17685 8.96347 0 12.2567 0H48.277V24H36.2702C39.5635 24 42.3851 25.1769 44.7436 27.5314C47.0935 29.8851 48.277 32.7086 48.277 36C48.277 39.2914 47.0935 42.114 44.7436 44.4686C42.3851 46.8223 39.5635 48 36.2702 48H0.25Z" fill="#FF3D00" />
                    </svg>

                    <svg width="242" height="36" viewBox="0 0 242 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.941 35.0268C10.0681 35.0268 6.98668 34.0745 4.69675 32.1698C2.40682 30.2652 1.06206 27.5465 0.662476 24.0136H4.35095C4.68906 26.5634 5.65729 28.4834 7.25563 29.7737C8.85398 31.0639 11.0671 31.709 13.8949 31.709C16.2924 31.709 18.0982 31.2559 19.3124 30.3497C20.5265 29.4434 21.1335 28.1148 21.1335 26.3637C21.1335 24.8584 20.7186 23.6604 19.8887 22.7695C19.0588 21.8786 17.7678 21.1413 16.0158 20.5576L10.4369 18.6223C7.60911 17.6392 5.51897 16.4488 4.16653 15.0511C2.81408 13.6533 2.13786 11.8792 2.13786 9.72883C2.13786 7.94707 2.58355 6.40339 3.47494 5.09778C4.36632 3.79218 5.62655 2.77074 7.25563 2.03346C8.88471 1.29618 10.7751 0.927539 12.9267 0.927539C16.2463 0.927539 18.9051 1.79539 20.903 3.53107C22.9009 5.26675 24.0536 7.73203 24.361 10.9269H20.6725C20.2729 8.62291 19.4507 6.93331 18.2058 5.85811C16.9609 4.7829 15.1705 4.2453 12.8345 4.2453C10.6214 4.2453 8.90007 4.69075 7.67058 5.58163C6.44109 6.47251 5.82634 7.73203 5.82634 9.36019C5.82634 10.7426 6.25667 11.8485 7.11732 12.678C7.97796 13.5074 9.31503 14.2293 11.1285 14.8437L17.0762 16.9173C19.6581 17.8082 21.5946 19.014 22.8856 20.5346C24.1765 22.0552 24.822 23.9829 24.822 26.3177C24.822 29.0825 23.8768 31.2252 21.9865 32.7458C20.0962 34.2665 17.4143 35.0268 13.941 35.0268Z" fill="#FF3D00" />
                        <path d="M35.2415 35.0728C33.7047 35.0728 32.3368 34.7579 31.1381 34.1281C29.9393 33.4984 29.0018 32.6075 28.3256 31.4555C27.6494 30.3035 27.3113 28.9748 27.3113 27.4695V11.1111H30.9998V26.5019C30.9998 28.2836 31.4378 29.6199 32.3138 30.5108C33.1898 31.4017 34.4424 31.8472 36.0714 31.8472C37.5468 31.8472 38.8532 31.5092 39.9904 30.8334C41.1277 30.1575 42.0268 29.2129 42.6876 27.9995C43.3485 26.786 43.6789 25.3959 43.6789 23.8292L44.2783 29.4049C43.5099 31.156 42.3111 32.5384 40.682 33.5521C39.053 34.5659 37.2395 35.0728 35.2415 35.0728ZM43.9094 34.612V29.0823H43.6789V11.1111H47.3674V34.612H43.9094Z" fill="#FF3D00" />
                        <path d="M63.5972 35.0261C61.3841 35.0261 59.5245 34.4885 58.0184 33.4133C56.5123 32.3381 55.4826 30.8175 54.9293 28.8514L55.4365 28.7592V34.6114H51.9785V1.43379H55.667V16.5941L55.1598 16.4559C55.7438 14.6434 56.812 13.2226 58.3642 12.1935C59.9164 11.1643 61.7684 10.6498 63.92 10.6498C66.0101 10.6498 67.8313 11.1567 69.3835 12.1704C70.9358 13.1842 72.1422 14.5973 73.0028 16.4098C73.8635 18.2223 74.2938 20.342 74.2938 22.7688C74.2938 25.2264 73.8481 27.3768 72.9568 29.22C72.0654 31.0632 70.8205 32.4917 69.2222 33.5055C67.6238 34.5193 65.7488 35.0261 63.5972 35.0261ZM63.044 31.8005C65.38 31.8005 67.2089 30.9941 68.5306 29.3813C69.8523 27.7685 70.5131 25.5644 70.5131 22.7688C70.5131 20.004 69.8523 17.8306 68.5306 16.2485C67.2089 14.6664 65.38 13.8754 63.044 13.8754C60.7079 13.8754 58.8637 14.6818 57.5112 16.2946C56.1588 17.9074 55.4826 20.1116 55.4826 22.9071C55.4826 25.6412 56.1588 27.8069 57.5112 29.4044C58.8637 31.0018 60.7079 31.8005 63.044 31.8005Z" fill="#FF3D00" />
                        <path d="M86.4193 35.026C83.8066 35.026 81.9085 34.4346 80.7252 33.2519C79.5418 32.0692 78.9501 30.3566 78.9501 28.114V5.62693L82.6386 4.24453V28.0679C82.6386 29.3889 82.9767 30.3566 83.6529 30.971C84.3291 31.5854 85.4357 31.8926 86.9725 31.8926C87.5873 31.8926 88.1175 31.8542 88.5632 31.7774C89.0089 31.7006 89.37 31.6007 89.6467 31.4778V34.6113C89.3393 34.7342 88.9013 34.834 88.3327 34.9108C87.764 34.9876 87.1262 35.026 86.4193 35.026ZM74.1089 14.2439V11.1105H89.6467V14.2439H74.1089Z" fill="#FF3D00" />
                        <path d="M95.3181 1.43379H91.6296V34.6114H95.3181V1.43379Z" fill="#FF3D00" />
                        <path d="M109.565 35.0724C107.321 35.0724 105.338 34.5425 103.617 33.4827C101.896 32.4228 100.543 30.9713 99.5598 29.1281C98.5762 27.2849 98.0844 25.1652 98.0844 22.7691C98.0844 20.3729 98.5685 18.2609 99.5367 16.433C100.505 14.6052 101.865 13.1844 103.617 12.1706C105.369 11.1569 107.382 10.65 109.657 10.65C111.839 10.65 113.737 11.1262 115.351 12.0785C116.965 13.0308 118.21 14.3671 119.086 16.0874C119.962 17.8078 120.4 19.8353 120.4 22.17C120.4 22.4772 120.392 22.769 120.377 23.0455C120.361 23.322 120.323 23.6292 120.261 23.9671H100.851V20.8798H118.002L116.803 22.5847C116.803 19.7585 116.181 17.585 114.936 16.0644C113.691 14.5438 111.932 13.7834 109.657 13.7834C107.321 13.7834 105.438 14.5898 104.009 16.2026C102.58 17.8154 101.865 19.9889 101.865 22.723C101.865 25.5185 102.58 27.7534 104.009 29.4276C105.438 31.1019 107.352 31.939 109.749 31.939C111.255 31.939 112.577 31.6087 113.714 30.9483C114.852 30.2878 115.681 29.3431 116.204 28.1143H119.708C118.94 30.2647 117.656 31.962 115.858 33.2062C114.06 34.4503 111.962 35.0724 109.565 35.0724Z" fill="#FF3D00" />
                        <path d="M134.555 35.0268C130.682 35.0268 127.6 34.0745 125.31 32.1698C123.02 30.2652 121.676 27.5465 121.276 24.0136H124.964C125.303 26.5634 126.271 28.4834 127.869 29.7737C129.468 31.0639 131.681 31.709 134.508 31.709C136.906 31.709 138.712 31.2559 139.926 30.3497C141.14 29.4434 141.747 28.1148 141.747 26.3637C141.747 24.8584 141.332 23.6604 140.502 22.7695C139.672 21.8786 138.381 21.1413 136.629 20.5576L131.05 18.6223C128.223 17.6392 126.133 16.4488 124.78 15.0511C123.428 13.6533 122.751 11.8792 122.751 9.72883C122.751 7.94707 123.197 6.40339 124.088 5.09778C124.98 3.79218 126.24 2.77074 127.869 2.03346C129.498 1.29618 131.389 0.927539 133.54 0.927539C136.86 0.927539 139.519 1.79539 141.517 3.53107C143.514 5.26675 144.667 7.73203 144.974 10.9269H141.286C140.886 8.62291 140.064 6.93331 138.819 5.85811C137.574 4.7829 135.784 4.2453 133.448 4.2453C131.235 4.2453 129.514 4.69075 128.284 5.58163C127.055 6.47251 126.44 7.73203 126.44 9.36019C126.44 10.7426 126.87 11.8485 127.731 12.678C128.591 13.5074 129.929 14.2293 131.742 14.8437L137.69 16.9173C140.272 17.8082 142.208 19.014 143.499 20.5346C144.79 22.0552 145.436 23.9829 145.436 26.3177C145.436 29.0825 144.49 31.2252 142.6 32.7458C140.71 34.2665 138.028 35.0268 134.555 35.0268Z" fill="#FF3D00" />
                        <path d="M157.699 35.026C155.087 35.026 153.189 34.4346 152.005 33.2519C150.822 32.0692 150.23 30.3566 150.23 28.114V5.62693L153.919 4.24453V28.0679C153.919 29.3889 154.257 30.3566 154.933 30.971C155.609 31.5854 156.716 31.8926 158.253 31.8926C158.867 31.8926 159.398 31.8542 159.843 31.7774C160.289 31.7006 160.65 31.6007 160.927 31.4778V34.6113C160.62 34.7342 160.181 34.834 159.613 34.9108C159.044 34.9876 158.406 35.026 157.699 35.026ZM145.389 14.2439V11.1105H160.927V14.2439H145.389Z" fill="#FF3D00" />
                        <path d="M170.333 35.0728C168.796 35.0728 167.428 34.7579 166.229 34.1281C165.031 33.4984 164.093 32.6075 163.417 31.4555C162.741 30.3035 162.403 28.9748 162.403 27.4695V11.1111H166.091V26.5019C166.091 28.2836 166.529 29.6199 167.405 30.5108C168.281 31.4017 169.534 31.8472 171.163 31.8472C172.638 31.8472 173.945 31.5092 175.082 30.8334C176.219 30.1575 177.118 29.2129 177.779 27.9995C178.44 26.786 178.77 25.3959 178.77 23.8292L179.37 29.4049C178.601 31.156 177.402 32.5384 175.773 33.5521C174.144 34.5659 172.331 35.0728 170.333 35.0728ZM179.001 34.612V29.0823H178.77V11.1111H182.459V34.612H179.001Z" fill="#FF3D00" />
                        <path d="M204.082 34.6114V29.1279L204.543 29.2661C203.959 31.0479 202.891 32.461 201.339 33.5055C199.787 34.55 197.935 35.0722 195.783 35.0722C193.662 35.0722 191.81 34.5653 190.227 33.5516C188.644 32.5378 187.415 31.1247 186.539 29.3122C185.663 27.4997 185.225 25.38 185.225 22.9532C185.225 20.4956 185.671 18.3452 186.562 16.502C187.453 14.6588 188.698 13.2303 190.297 12.2165C191.895 11.2028 193.77 10.6959 195.921 10.6959C198.135 10.6959 199.994 11.2335 201.5 12.3087C203.006 13.3839 204.036 14.9045 204.589 16.8706L203.852 16.9628V1.43379H207.54V34.6114H204.082ZM196.475 31.8466C198.842 31.8466 200.693 31.0402 202.03 29.4274C203.368 27.8146 204.036 25.6104 204.036 22.8149C204.036 20.0808 203.36 17.9151 202.007 16.3176C200.655 14.7202 198.811 13.9215 196.475 13.9215C194.139 13.9215 192.31 14.7202 190.988 16.3176C189.666 17.9151 189.006 20.1269 189.006 22.9532C189.006 25.718 189.666 27.8914 190.988 29.4735C192.31 31.0556 194.139 31.8466 196.475 31.8466Z" fill="#FF3D00" />
                        <path d="M212.15 34.6114V11.1106H215.839V34.6114H212.15ZM211.874 5.76531V1.43379H216.115V5.76531H211.874Z" fill="#FF3D00" />
                        <path d="M230.131 35.0724C227.826 35.0724 225.797 34.5348 224.045 33.4596C222.293 32.3844 220.933 30.9175 219.965 29.059C218.997 27.2004 218.513 25.1038 218.513 22.7691C218.513 20.4036 219.004 18.3147 219.988 16.5022C220.972 14.6897 222.332 13.2612 224.068 12.2167C225.805 11.1722 227.826 10.65 230.131 10.65C232.467 10.65 234.504 11.1722 236.24 12.2167C237.977 13.2612 239.329 14.6897 240.298 16.5022C241.266 18.3147 241.75 20.4036 241.75 22.7691C241.75 25.1038 241.266 27.2004 240.298 29.059C239.329 30.9175 237.977 32.3844 236.24 33.4596C234.504 34.5348 232.467 35.0724 230.131 35.0724ZM230.131 31.939C231.73 31.939 233.113 31.5473 234.281 30.7639C235.449 29.9806 236.356 28.8977 237.001 27.5153C237.647 26.1329 237.969 24.5355 237.969 22.723C237.969 20.0196 237.255 17.8538 235.825 16.2257C234.396 14.5975 232.498 13.7834 230.131 13.7834C227.795 13.7834 225.905 14.5975 224.46 16.2257C223.016 17.8538 222.293 20.0196 222.293 22.723C222.293 24.5355 222.616 26.1329 223.261 27.5153C223.907 28.8977 224.821 29.9806 226.005 30.7639C227.188 31.5473 228.564 31.939 230.131 31.939Z" fill="#FF3D00" />
                    </svg>
                </div>
            </section>
        </>
    );
};

export default Header;