import './ServicesWrapper.css';
import React, { useState } from 'react';

const ServicesWrapper = ({ service, description }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleServices = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <div className={`services-wrapper ${isOpen ? 'active' : ''}`} onClick={toggleServices}>
            <div className="services-header-wrapper">
                <span>
                    {service}
                </span>

                <div className={`plusminus ${isOpen ? 'active' : ''}`}></div>
            </div>
            <p className={isOpen ? 'active' : ''}>{description}</p>
        </div>
    );
}

export default ServicesWrapper;;