import './ContactUs.css';

const ContactUs = () => {
    return (
      <div >
ContactUs
      </div>
    );
  };
  
  export default ContactUs;
  